import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["startTime", "endTime", "selectedSchedule"]

  connect() {
    this.element.addEventListener('submit', this.handleSubmit.bind(this))
  }

  handleSubmit(event) {
    let valid = true

    if (this.hasStartTimeTarget) {
      if (this.startTimeTarget.value.trim() === "") {
        this.showError(this.startTimeTarget, "Start time is required.")
        valid = false
      } else {
        this.clearError(this.startTimeTarget)
      }
    }

    if (this.hasEndTimeTarget) {
      if (this.endTimeTarget.value.trim() === "") {
        this.showError(this.endTimeTarget, "End time is required.")
        valid = false
      } else {
        this.clearError(this.endTimeTarget)
      }
    }

    if (this.hasSelectedScheduleTarget) {
      if (this.selectedScheduleTarget.value === "none") {
        this.showError(this.selectedScheduleTarget, "Please select a valid recurrence option.")
        valid = false
      } else {
        this.clearError(this.selectedScheduleTarget)
      }
    }

    if (!valid) {
      event.preventDefault() // Prevent form submission if validation fails
    }
  }

  showError(target, message) {
    let errorElement = target.nextElementSibling
    if (errorElement && errorElement.classList.contains("flatpickr-calendar")) {
      errorElement = errorElement.nextElementSibling
    }

    if (!errorElement || !errorElement.classList.contains("error-message")) {
      errorElement = document.createElement("div")
      errorElement.className = "error-message text-red-500 text-sm mt-1"
      target.parentNode.appendChild(errorElement)
    }

    errorElement.textContent = message
    target.classList.add("border-red-500")
  }

  clearError(target) {
    let errorElement = target.nextElementSibling
    if (errorElement && errorElement.classList.contains("flatpickr-calendar")) {
      errorElement = errorElement.nextElementSibling
    }

    if (errorElement && errorElement.classList.contains("error-message")) {
      errorElement.remove()
    }

    target.classList.remove("border-red-500")
  }
}
