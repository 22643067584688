import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.css"

export default class extends Controller {
  static values = {
    type: String,
    time24hr: { type: Boolean, default: false },
    dateChange: { type: Boolean, default: false },
    endTime: { type: Boolean, default: false },
    static: { type: Boolean, default: false },
    id: { type: String, default: 'unset-id' } // Specify same value on end/start if using multiple end/start on single page as well as schedule_select
  }

  initialize () {
    this.checkInitialValue = this.checkInitialValue.bind(this)
    this.currentValue = null
    this.handleDateChangeEvent = this.handleDateChangeEvent.bind(this)
    this.onOpen = this.onOpen.bind(this)
    this.isInitialLoad = true // Flag to indicate if it's the initial load
  }

  connect () {
    let options = {
      enableTime: true,
      time_24hr: this.time24hrValue,
      minuteIncrement: 1,
      static: this.staticValue,
      onOpen: this.onOpen,  // Hook to set the default date when the picker is opened
      onChange: (selectedDates, dateStr, instance) => {
        this.element.value = dateStr

        let originalValue = null
        if (this.currentValue) {
          originalValue = new Date(this.currentValue.getTime())
        } else if (selectedDates.length > 0) {
          originalValue = new Date(selectedDates[0].getTime())
        }

        if (this.dateChangeValue) {
          this.dispatchDateChangeEvent(selectedDates, originalValue)
        }

        // Update the stored value to the new date after dispatching the event
        if (selectedDates.length > 0) {
          this.currentValue = new Date(selectedDates[0].getTime())
        } else {
          this.currentValue = null
        }

      }
    }

    if (this.typeValue === 'time') {
      options.noCalendar = true
      options.dateFormat = this.time24hrValue ? "H:i" : "h:i K"
    } else if (this.typeValue === 'datetime') {
      options.dateFormat = this.time24hrValue ? "Y-m-d H:i" : "Y-m-d h:i K"
    } else if (this.typeValue === 'date') {
      options.enableTime = false
      options.dateFormat = "Y-m-d"
    }

    // Check if flatpickr is already initialized
    if (!this.element._flatpickr) {
      flatpickr(this.element, options)
    }

    // Use setTimeout to delay the initial value check
    setTimeout(this.checkInitialValue, 0)

    // Listen for date-change events if endTime is true
    if (this.endTimeValue) {
      document.addEventListener(`date-change-${this.idValue}`, this.handleDateChangeEvent)
    }
  }

  disconnect () {
    if (this.endTimeValue) {
      document.removeEventListener(`date-change-${this.idValue}`, this.handleDateChangeEvent)
    }
  }

  dispatchDateChangeEvent (selectedDates, originalValue) {
    // Don't dispatch event if this is the endTime picker
    if (this.endTimeValue) {
      return
    }

    if (selectedDates.length > 0) {
      const event = new CustomEvent(`date-change-${this.idValue}`, {
        detail: {
          date: selectedDates[0],
          originalDate: originalValue
        }
      })
      document.dispatchEvent(event)
    }
  }

  handleDateChangeEvent (event) {
    const newStartDate = event.detail.date
    const originalStartDate = event.detail.originalDate // Explicitly assign for clarity
    const originalEndValue = this.element.value

    // Only modify end time if it's not the initial load
    if (!this.isInitialLoad) {
      let newEndDate

      if (originalStartDate && originalEndValue) {
        const originalEndDate = new Date(originalEndValue)
        // Calculate the time difference using the original start and end values
        const timeDifference = originalEndDate.getTime() - originalStartDate.getTime()
        newEndDate = new Date(newStartDate.getTime() + timeDifference)
      } else {
        // Default behavior: Add one hour to the new start date if no initial end value
        newEndDate = new Date(newStartDate)
        newEndDate.setHours(newEndDate.getHours() + 1)
      }

      // Format the new end date properly
      const year = newEndDate.getFullYear()
      const month = ('0' + (newEndDate.getMonth() + 1)).slice(-2)
      const day = ('0' + newEndDate.getDate()).slice(-2)
      const hours = ('0' + newEndDate.getHours()).slice(-2)
      const minutes = ('0' + newEndDate.getMinutes()).slice(-2)

      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`
      this.element.value = formattedDate

      // Reinitialize flatpickr with the new end date
      this.reinitializeFlatpickr(formattedDate)

      // Update the original end value to the new end date
      this.originalEndValue = newEndDate
    }
  }

  // Helper function to reinitialize flatpickr with a new date
  reinitializeFlatpickr (newDate) {
    // Destroy the existing flatpickr instance if it exists
    if (this.element._flatpickr) {
      this.element._flatpickr.destroy()
    }

    // Reinitialize flatpickr with the updated options and new date
    let options = {
      enableTime: true,
      time_24hr: this.time24hrValue,
      static: this.staticValue,
      minuteIncrement: 1,
      defaultDate: newDate,
      onChange: (selectedDates, dateStr, instance) => {
        this.element.value = dateStr
        if (this.dateChangeValue) {
          this.dispatchDateChangeEvent(selectedDates)
        }
      }
    }

    flatpickr(this.element, options)
  }

  checkInitialValue () {
    if (this.element.value && this.dateChangeValue) {
      const currentDate = new Date(this.element.value)
      if (!isNaN(currentDate)) {
        const event = new CustomEvent(`date-change-${this.idValue}`, {
          detail: { date: currentDate }
        })
        document.dispatchEvent(event)
      }
    }
    this.isInitialLoad = false // Set flag to false after the initial load is done
  }

  onOpen (selectedDates, dateStr, instance) {
    if (!this.element.value) {
      const now = new Date()
      this.currentValue = now

      if (this.typeValue === 'datetime') {
        // Set current date and time for datetime pickers
        instance.setDate(now, true)
      } else if (this.typeValue === 'date') {
        // Set current date for date pickers (time disabled)
        instance.setDate(now.toISOString().split('T')[0], true)
      } else if (this.typeValue === 'time') {
        // Set current time for time pickers (date disabled)
        const timeString = now.toTimeString().split(' ')[0] // Format HH:MM:SS
        instance.setDate(timeString, true)
      }
    }
  }
}

