import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = [ "input", "results", "customerIdField" ]
  static values = {
    name: String
  }

  connect() {
    console.log("CustomerSearchController connected")
    if (this.nameValue && this.nameValue.length > 0) {
      this.inputTarget.value = this.nameValue
    }
    this.search = debounce(this.search.bind(this), 300)
    this.currentFocus = -1

    // Add event listeners for keyboard support
    this.inputTarget.addEventListener("keydown", this.handleKeydown.bind(this))
    document.addEventListener("click", this.handleDocumentClick.bind(this))
  }

  disconnect() {
    this.inputTarget.removeEventListener("keydown", this.handleKeydown.bind(this))
    document.removeEventListener("click", this.handleDocumentClick.bind(this))
  }

  handleKeydown(event) {
    const { key } = event
    const items = this.resultsTarget.children

    switch (key) {
      case "Escape":
        this.clearResults()
        this.customerIdFieldTarget.value = ""
        this.inputTarget.value = ""
        break
      case "Tab":
      case "ArrowDown":
        event.preventDefault()
        this.currentFocus++
        this.addActive(items)
        break
      case "ArrowUp":
        event.preventDefault()
        this.currentFocus--
        this.addActive(items)
        break
      case "Enter":
        event.preventDefault()
        if (this.currentFocus > -1 && items.length > 0) {
          items[this.currentFocus].click()
        }
        break
    }
  }

  handleDocumentClick(event) {
    if (!this.element.contains(event.target)) {
      this.clearResults()
    }
  }

  addActive(items) {
    if (!items) return

    this.removeActive(items)

    if (this.currentFocus >= items.length) this.currentFocus = 0
    if (this.currentFocus < 0) this.currentFocus = items.length - 1

    items[this.currentFocus].classList.add("bg-gray-100", "text-blue-600")
  }

  removeActive(items) {
    for (let item of items) {
      item.classList.remove("bg-gray-100", "text-blue-600")
    }
  }

  search() {
    const query = this.inputTarget.value.trim()

    if (query.length < 2) {
      this.customerIdFieldTarget.value = ""
      this.clearResults()
      return
    }

    fetch(`/customers_search?query=${encodeURIComponent(query)}`)
      .then(response => response.json())
      .then(data => {
        this.renderResults(data)
      })
      .catch(error => {
        console.error('Error fetching customers:', error)
        this.clearResults()
      })
  }

  renderResults(customers) {
    this.clearResults()

    customers.forEach(customer => {
      const div = document.createElement("div")
      div.textContent = customer.name
      div.dataset.customerId = customer.id
      div.classList.add("results-item", "cursor-pointer", "p-2", "border-b", "border-gray-200", "transition-colors", "hover:bg-gray-100", "hover:text-blue-600")
      div.addEventListener('click', () => this.selectCustomer(customer))
      this.resultsTarget.appendChild(div)
    })

    this.resultsTarget.style.display = 'block'
  }

  selectCustomer(customer) {
    this.inputTarget.value = customer.name
    this.customerIdFieldTarget.value = customer.id
    this.clearResults()
  }

  clearResults() {
    this.resultsTarget.innerHTML = ""
    this.resultsTarget.style.display = 'none'
    this.currentFocus = -1
  }
}
