/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const validateLabelCount = function(event) {

  const count = $("#copies_count")[0].value;

  if (count > 9) {
    const result = confirm("Are you sure you want to print " + count + " labels?");
    if (result === false) {
      event.preventDefault();
      return false;
    }
  }

  return true;
};

const setLinkPosition = function(position) {
  let url = $("#google_maps_link").attr("href");
  url = url.replace(
    "Current+Location",
    position.coords.latitude + "," + position.coords.longitude
  );
  return $("#google_maps_link").attr("href", url);
};

const copyForms = ($form1, $form2) => $(":input[name]", $form2).val(function() {
  return $(":input[name='" + this.name + "']", $form1).val();
});

const registerCustomerEvents = function() {
  $("form").on("click", ".remove_fields", function(event) {
    $(this).prev("input[type=hidden]").val("1");
    $(this).closest("fieldset").hide();
    return event.preventDefault();
  });

  $("form").on("click", ".add_fields", function(event) {
    const time = new Date().getTime();
    const regexp = new RegExp($(this).data("id"), "g");
    $(this).before($(this).data("fields").replace(regexp, time));
    return event.preventDefault();
  });

  $("#create_new_customer_form").on("click", event => copyForms(
    $("#search_customer")[0],
    event.target.form
  ));

  return $("#print_property_label").click(event => validateLabelCount(event));
};

const prepareCustomerScript = function() {
  registerCustomerEvents();

  if ($("#google_maps_link").length && navigator.geolocation) {
    return navigator.geolocation.getCurrentPosition(setLinkPosition);
  }
};


$(document).ready(prepareCustomerScript);
$(document).on("turbolink:load", prepareCustomerScript);
