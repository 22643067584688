// app/javascript/controllers/form_persistence_controller.js
import { Controller } from '@hotwired/stimulus'
import localforage from 'localforage'

export default class extends Controller {
  static targets = ['form']

  connect() {
    // Load saved form data when the controller connects
    this.loadFormData()

    // Add event listeners for form input changes
    this.element.addEventListener('input', this.saveFormData.bind(this))
    this.element.addEventListener('change', this.saveFormData.bind(this))

    // Save data before navigation (for Turbo or Turbolinks)
    document.addEventListener('turbo:before-cache', this.saveFormData.bind(this))
  }

  disconnect() {
    // Save form data when the controller disconnects
    this.saveFormData()

    // Remove listeners
    this.element.removeEventListener('input', this.saveFormData.bind(this))
    this.element.removeEventListener('change', this.saveFormData.bind(this))
    document.removeEventListener('turbo:before-cache', this.saveFormData.bind(this))
  }

  loadFormData() {
    const formKey = this.formKey()

    localforage.getItem(formKey).then((data) => {
      if (data) {
        Object.entries(data).forEach(([key, value]) => {
          const input = this.element.querySelector(`[name="${key}"]`)
          if (input) {
            input.value = value
          }
        })
      }
    })
  }

  saveFormData() {
    const formKey = this.formKey()
    const formData = new FormData(this.element)

    const data = {}
    formData.forEach((value, key) => {
      data[key] = value
    })

    localforage.setItem(formKey, data).catch((err) => {
      console.error('Failed to save form data:', err)
    })
  }

  clearFormData() {
    const formKey = this.formKey()

    localforage.removeItem(formKey).then(() => {
      this.element.reset()
    })
  }

  formKey() {
    return `form-${this.element.dataset.persistKey || this.element.action}`
  }
}
