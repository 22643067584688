import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectedSchedule"]
  static values = {
    disabled: { type: Boolean, default: true },
    recurrenceEvery: String,
    recurrenceFrequency: Number,
    id: { type: String, default: 'unset-id' } // Specify same value on end/start for flatpicker controller
  }

  connect () {
    // Listen for date-change events that are specific to this instance
    document.addEventListener(`date-change-${this.idValue}`, this.updateScheduleOptions.bind(this))
    this.setInitialSelectedSchedule()
    if (this.disabledValue) {
      this.disableSelectedSchedule()
    }
  }

  disconnect () {
    // Remove event listener when the controller is disconnected
    document.removeEventListener(`date-change-${this.idValue}`, this.updateScheduleOptions.bind(this))
  }

  updateScheduleOptions (event) {
    const date = new Date(event.detail.date)
    if (isNaN(date)) return

    const day = date.getDate()
    const month = date.toLocaleString('default', { month: 'long' })
    const year = date.getFullYear()
    const dayOfWeek = date.toLocaleString('default', { weekday: 'long' })

    // Calculate week of the month
    const weekOfMonth = Math.ceil(day / 7)
    const lastWeekOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - day < 7
    const weekOfMonthText = lastWeekOfMonth ? `last ${dayOfWeek}` : `${this.ordinalSuffix(weekOfMonth)} ${dayOfWeek}`

    // Update schedule options based on selected date
    this.selectedScheduleTarget.options[2].text = `Weekly on ${dayOfWeek}`
    this.selectedScheduleTarget.options[3].text = `Monthly on the ${weekOfMonthText}`
    this.selectedScheduleTarget.options[4].text = `Quarterly on the ${weekOfMonthText}`
    this.selectedScheduleTarget.options[5].text = `Annually on ${month} ${day}`

    this.selectedScheduleTarget.disabled = false
  }

  disableSelectedSchedule () {
    this.selectedScheduleTarget.disabled = true
  }

  ordinalSuffix (i) {
    const j = i % 10,
          k = i % 100
    if (j == 1 && k != 11) return i + "st"
    if (j == 2 && k != 12) return i + "nd"
    if (j == 3 && k != 13) return i + "rd"
    return i + "th"
  }

  setInitialSelectedSchedule () {
    const recurrenceEvery = this.recurrenceEveryValue
    const recurrenceFrequency = this.recurrenceFrequencyValue
    let selectedValue = "none"

    if (recurrenceEvery === "day") {
      selectedValue = "day"
    } else if (recurrenceEvery === "week") {
      selectedValue = "week"
    } else if (recurrenceEvery === "month" && recurrenceFrequency === 1) {
      selectedValue = "month"
    } else if (recurrenceEvery === "month" && recurrenceFrequency === 3) {
      selectedValue = "quarter"
    } else if (recurrenceEvery === "year") {
      selectedValue = "year"
    }

    this.selectedScheduleTarget.value = selectedValue
  }
}
