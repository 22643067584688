/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const registerPaymentEvents = function() {
  // Return Button
  $("#partial_return_button").click(function(e) {
    e.preventDefault();
    return $("#payment_undo_dialog").dialog({
      width: 450,
      });
  });
};

const onPaymentPage = () => registerPaymentEvents();

$(document).ready(onPaymentPage);
$(document).on("turbolink:load", onPaymentPage);
