/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
var calendarUpdate = () => $(".calendar_update").click(function() {
  const {
    date
  } = $(this)[0].dataset;
  return $.get("workspace/calendar_update", {"new_date": date}, calendarUpdate, "script");
});

const timePunch = () => $.ajax({
  url: "/time_punch",
  type: "POST",
  dataType: "json",
  data: {},
  success(data) {
    return toastr["success"](`${data['message']}`);
  },
  error(jqXHR, textStatus, errorThrown) {
    return toastr["error"](`Failed! ${textStatus}`);
  }
});

const onWorkspacePage = function() {
  calendarUpdate();

  return $("#time_punch_button").click(function(e) {
    e.preventDefault();
    return timePunch();
  });
};


$(document).ready(onWorkspacePage);
$(document).on('turbolink:load', onWorkspacePage);
