/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const setPerPageLimit = () => $("#limit_form select").change(function() {
  $("#limit_form").submit();
  return setTimeout(
    () => location.href = location.href.replace(/&?page=([^&]$|[^&]*)/i, ""),
    1500
  );
});

$(document).ready(setPerPageLimit);
$(document).on('turbolink:load', setPerPageLimit);
