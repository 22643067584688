/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// root will be exported to global name space
const root = typeof exports !== 'undefined' && exports !== null ? exports : this;

const readySections = function(sectionClassName) {
  $('.' + sectionClassName).sortable({
    items: 'div',
    axis: 'y',
    update() {
      return updateSections(sectionClassName);
    },
    start() {
      return fixedWidth(sectionClassName);
    }
  });

  $('.' + sectionClassName + '_item input').checkboxradio();
  return $('.' + sectionClassName).on('change', handleToggle);
};

root.readySections = readySections;

var updateSections = function(urlClass) {
  const {
    updateUrl
  } = $('.' + urlClass)[0].dataset;
  const section_settings = $('.' + urlClass + '_item');
  const parameters = {};

  let index = 0;
  while (index < section_settings.length) {
    var position;
    const key = section_settings[index].dataset.section;
    const value = parseInt(section_settings[index].dataset.position, 10);

    const section_container = $('.workspace_section_container[data-container-section="' + key + '"]')[0];

    // Negative position numbers represent that that section has been disabled
    if (value > 0) {
      position = index + 1;
      section_container.style.display = "";
    } else {
      position = -(index + 1);
      section_container.style.display = "none";
    }
    parameters[key] = position;
    section_container.dataset.containerPosition = position;

    index++;
  }

  $.post(updateUrl, parameters);

  const workspace_sections = $('.workspace_section_container').sort((a, b) => Math.abs(~~a.dataset.containerPosition) > Math.abs(~~b.dataset.containerPosition));

  workspace_sections.appendTo(".workspace_tabcontent");
};


var handleToggle = function(e) {
  const target = $(e.target);

  const section = target[0].id.substring(0, target[0].id.lastIndexOf('_'));
  const record = $('div').find("[data-section='" + section + "']")[0];
  const position = Math.abs(record.dataset.position);
  record.dataset.position = target.is(':checked') ? position : -position;

  const urlClass = record.className.substring(0, record.className.lastIndexOf('_'));
  updateSections(urlClass);

};

var fixedWidth = function(sectionClassName) {
    let max_width = 0;
    const content_div = $('.' + sectionClassName);

    // width is not calculated for display: none elements
    // so make sure width is calculated
    const previous_css  = content_div.attr('style');

    content_div.css({
      position:   'absolute',
      visibility: 'hidden',
      display:    'block'
    });

    // get the max width from all the children
    const children = content_div.children();
    for (var i = 0, end = children.length, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
      (() => {
        const w = children[i].clientWidth;
        if (w > max_width) {
          return max_width = w;
        }
      })();
    }

    // reset css
    content_div.attr('style', previous_css ? previous_css : '');

    // set the width of the table to be the max
    content_div.css('min-width', max_width);

  };
