/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const setCollapseEvents = function() {
  $('.header').click(function(event) {
    // event.preventDefault
    // console.log("Fired 1")
    return $(this).toggleClass('expand').nextUntil('tr.header').slideToggle(10);
  });
    // console.log("End")
    // return false

  return $('.collapse_arrow').click(function() {
    $(this).toggleClass('expand');
    return $('#search_form').slideToggle(10);
  });
};



$(document).ready(setCollapseEvents);
$(document).on('turbolink:load', setCollapseEvents);
