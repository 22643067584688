/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Receipt Dialog
const dialogInitialize = function(id, target, height) {
  return $(target).dialog({
    autoOpen: true,
    height,
    width: 550,
    modal: true,
    buttons: {
      "Close"() {
        return $(this).dialog( "close" );
      }
    },
    close() {}
      // location.href = location.href.split("?")[0]
  });
};


const registerNewBillEvents = function() {
  $(".email-invoice-button, .email-bill.button-to, .email-quote.button-to").click(function(e) {
      e.preventDefault();

      const id = this.dataset.serviceRequestId;
      const target = "#email_invoice_dialog_" + id;

      dialogInitialize(id, target, 250);
      return false;
  });

  $(".saved-payment-method-button").click(function(e) {
      e.preventDefault();

      const id = this.dataset.serviceRequestId;
      const target = "#saved-payment-method-dialog-" + id;

      dialogInitialize(id, target, 270);
      return false;
  });

  $("input.datalist_filter").on("focus", e => {
    e.target.placeholder = e.target.value;
    return e.target.value = "";
  });

  $("input.datalist_filter").on("blur", e => {
    if ((e.target.value === "") && (e.target.placeholder !== "")) {
      return e.target.value = e.target.placeholder;
    }
  });

  $("form.edit_service_request").on("submit", e => {
    return sessionStorage.scrollTop = $(window).scrollTop();
  });

  if (sessionStorage.scrollTop !== "undefined") {
    $(window).scrollTop(sessionStorage.scrollTop);
    return sessionStorage.scrollTop = "undefined";
  }
};


const onBillPage = () => registerNewBillEvents();

$(document).ready(onBillPage);
$(document).on("turbolink:load", onBillPage);
