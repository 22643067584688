/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
import 'controllers'
import 'channels'

import Rails from "@rails/ujs"
import $ from 'jquery'
import Vue from 'vue'
import 'jquery-ui'


import autosize from 'autosize'

import 'jquery-mask-plugin'
import flatpickr from 'flatpickr'
import 'foundation-sites'

import Cookies from 'js-cookie'

import 'styles/application'

// // Legacy Javascript
// // Created before webpacker
import '@legacy/best_in_place'
import '@legacy/login-ping'

import '@legacy/sitewide/behavior'
import '@legacy/sitewide/collapsible'
import '@legacy/sitewide/dropdown'
import '@legacy/sitewide/get-elements-by-selector'
import '@legacy/sitewide/jquery-combobox-widget'
import '@legacy/sitewide/paginator'
import '@legacy/sitewide/workspace'

import '@legacy/point_of_sale/display_list'

import '@legacy/bills'
import '@legacy/customers'
import '@legacy/line_items'
import '@legacy/parts_calc'
import '@legacy/payments'
import '@legacy/service_requests'

import toastr from '@legacy/toastr.min'

import clipElement from '@/helpers/clip-element'
window.clipElement = clipElement

const images = require.context('../images', true)
const imagePath = (name) => images(name, true) // eslint-disable-line no-unused-vars

// console.log('Hello World from Webpacker')
// import initVueApp from '@/main'
// initVueApp()

// Disabled Turbolinks since it wouldn't play nicely with
// Foundation's reveal widget.
// require('turbolinks').start()

// require('channels')

// THIS IS MAKING jQuery AVAILABLE EVEN INSIDE Views FOLDER
global.$ = $

window.Cookies = Cookies

global.toastr = toastr

$.jMaskGlobals.watchDataMask = true

// /*global toastr*/
$(document).ready(function () {
    toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: 'toast-top-right',
        preventDuplicates: false,
        onclick: null,
        showDuration: '300',
        hideDuration: '1000',
        timeOut: '5000',
        extendedTimeOut: '1000',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut'
    }

    $(function () { $(document).foundation() })

    const flatpickrConfig = {
        allowInput:true,
        onOpen: function(selectedDates, dateStr, instance) {
            $(instance.altInput).prop('readonly', true);
        },
        onClose: function(selectedDates, dateStr, instance) {
            $(instance.altInput).prop('readonly', false);
            $(instance.altInput).blur();
        }
    }

    flatpickr('.date-picker', flatpickrConfig)
    $(function () { $('.best_in_place').best_in_place() })
    $.fn.extend({ autosize: autosize })
})

$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  }
});

Rails.start()
// document.addEventListener('turbolinks:load', () => $(document).foundation())
