/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

const addActiveOrders = function(type, id) {
  const $parameter_key = type + "_id";
  const $data = {};
  $data[$parameter_key] = id;
  const $old_count = updateShoppingCartCount();
  return $.ajax({
    url: "/active_orders",
    beforeSend(xhr) {
      return xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    type: "POST",
    dataType: "json",
    data: $data,
    success(data) {
      const $new_count = updateShoppingCartCount();
      if ($new_count > $old_count) {
        return toastr["success"](
          "Added " + ($new_count - $old_count).toString() + " item(s) to cart."
        );
      } else {
        return toastr["error"]("No new items added.");
      }
    },
    error(data) {
      return toastr["error"]("Add to cart failed!");
    }
  });
};

const showCart = function() {
  $("#load_cart_button").css("display", "none");
  return $(".cart_line_item").each((index, element) => $(element).css("display", ""));
};

const hideCart = function() {
  $("#load_cart_button").css("display", "");
  return $(".cart_line_item").each((index, element) => $(element).css("display", "none"));
};

const calculateTotal = function() {
  let $amount = 0.0;
  $(".visible_order_amounts td input:last-child").each(function(index, element) {
    if ($(element).is(":visible")) {
      if ($(element).val() === "") {
        return;
      }

      return $amount += parseFloat($(element).val());
    }
  });

  return $amount;
};

const calculateOrderAmountsSum = function() {
  const $amount = calculateTotal();

  return $("input#visible_payment_total").val($amount.toFixed(2));
};

const ordersList = function(reject) {
  if (reject == null) { reject = 0; }
  let $orders = [];
  try { $orders = JSON.parse(Cookies.get("orders")); }
  catch (e) {}
  if (reject !== 0) {
    $orders = (Array.from($orders).filter((order) => order !== reject));
  }

  return $orders;
};

const transactionOrdersList = function() {
  let $orders = [];
  try { $orders = JSON.parse(Cookies.get("transaction_orders")); }
  catch (e) {}

  return $orders;
};

const ordersInTransaction = function() {
  const $orders = ordersList();
  const $transaction_orders = transactionOrdersList();
  for (let order of Array.from($orders)) {
    if (Array.from($transaction_orders).includes(order)) {
      return true;
    }
  }

  return false;
};

const removeButton = function(button) {
  const $order_row = $(button).closest("tr");
  $order_row.css("display", "none");

  let $is_cart = false;
  const $order = parseInt($order_row.find("> input")[0].value);
  if ($order === parseInt(Cookies.get("cart"))) {
    $is_cart = true;
  }

  let $orders = [];
  try { $orders = JSON.parse(Cookies.get("orders")); }
  catch (e) {}
  $orders = (Array.from($orders).filter((order) => order !== $order));
  Cookies.set("orders", JSON.stringify($orders));

  if ($is_cart) {
    // hideCart()
    return $.ajax("/empty_cart", {
      type: "POST",
      success(data) {
        localStorage.setItem("toastr_type", "success");
        localStorage.setItem("toastr_message", "Order removed.");

        return location.href = location.href.split("?")[0];
      },
      error(data) {
        toastr["error"]("Cart removal failed.");
        return console.log(data);
      }
    }
    );
  } else {
    updateShoppingCartCount();
    calculateOrderAmountsSum();

    localStorage.setItem("toastr_type", "success");
    localStorage.setItem("toastr_message", "Order removed.");

    return location.href = location.href.split("?")[0];
  }
};

var updateShoppingCartCount = function() {
  let $total_count, needle;
  const $cart = parseInt(Cookies.get("cart"));
  let $cart_count = parseInt(Cookies.get("cart_count"));
  if (Number.isNaN($cart_count)) {
    $cart_count = 0;
  }
  if ((needle = $cart, Array.from(ordersList()).includes(needle))) {
    let $orders_count = ordersList($cart).length;
    if (Number.isNaN($cart_count)) {
      $orders_count = 0;
    }
    $total_count = $orders_count + $cart_count;
  } else {
    $total_count = ordersList().length;
  }

  $("#shopping_cart_count").text($total_count);
  return $total_count;
};

// removeOrderFromOrdersList = ->
//   $($(".remove_button")[0].closest("tr")).find("> input")[0].value

const registerOrderListEvents = function() {
  // Remove Item Button
  $(".remove_button").click(function(e) {
    e.preventDefault();
    if (!$(this).parent().hasClass("disabled")) {
      removeButton(this);
    } else {
      toastr["error"](
        `Cannot remove this order from in-progress transaction. \
Complete/close current sales transaction.`
      );
    }
    return false;
  });

  // Add Orders for Checkout
  $(".add_to_cart_button, .add_to_cart_link").click(function(e) {
    e.preventDefault();
    return addActiveOrders($(this).data("type"), $(this).data("id"));
  });

  // Load Cart for Checkout
  $("#load_cart_button").click(function(e) {
    e.preventDefault();

    $("#order_id").val($("#cart_list").data("cart_id"));
    $("#add_orders input[type=\"submit\"]")[0].click();
    let $orders = [];
    try { $orders = JSON.parse(Cookies.get("orders")); }
    catch (error) { e = error; }
    $orders.push(Cookies.get("cart"));
    return Cookies.set("orders", JSON.stringify($orders));
  });

  // Quick Pay Button
  $(".payment_button, .payment_link").click(function(e) {
    // Clear other orders from cart
    if (!$(this).hasClass("no_customer_payment_button")) {
      const $orders = ordersList();
      const $transaction_orders = transactionOrdersList();
      if (($transaction_orders.length > 0) && confirm("This will close out the current sales transaction.")) {
        transactionComplete();
        return Cookies.set("quick_payment", "true");
      } else if (
          (
            (($orders.length > 1) && ($transaction_orders.length === 0)) &&
            confirm("This will replace your current cart list.")
          ) ||
          (($transaction_orders.length === 0) && ($orders.length <= 1))
        ) {
        Cookies.remove("orders");
        return Cookies.set("quick_payment", "true");
      } else {
        e.preventDefault();
        return false;
      }
    }
  });

  // Open Add To Transaction Dialog
  $("#add_item_button").click(function(e) {
    e.preventDefault();
    return $("#add_to_transaction_dialog").dialog({
      autoOpen: true,
      height: 380,
      width: 276,
      modal: true,
      buttons: {
        "Close"() {
          return $( this ).dialog( "close" );
        }
      },
      close() {
        //allFields.val("").removeClass "ui-state-error"
        return location.reload(true);
      }
    });
  });

  // Recalculate order amounts on change
  $(".visible_order_amounts td input:last-child").each((index, element) => $(element).on("keyup change", e => calculateOrderAmountsSum()));


  $("#shopping_cart").click(e => Cookies.set("shopping_cart", "clicked"));

  // Reset current sales transaction
  $("#reset_cart_button").click(function(e) {
    const $transaction_orders = transactionOrdersList();
    if (($transaction_orders.length > 0) && confirm("This will close out the current sales transaction and VOID/RETURN all payments.")) {
      return $.ajax({
        url: "/cancel_sales_transaction",
        type: "POST",
        success(data) {
          Cookies.remove("orders");
          Cookies.remove("transaction_orders");
          return location.href = location.href.split("?")[0];
        },
        error(data) {}
      });
    } else if ($transaction_orders.length === 0) {
      Cookies.remove("orders");
      return Cookies.remove("transaction_orders");
    } else {
      e.preventDefault();
      return false;
    }
  });

  // Prevent create payment if order amounts are zero
  return $(".new_payment_button").click(function(e) {
    const $amount = calculateTotal();
    if ($amount <= 0.0001) {
      e.preventDefault();
      toastr["error"](
        `Please set at least one 'Amount To Pay' \
greater than zero before taking a payment.`
      );
      return false;
    }
  });
};

const runToastr = function() {
  const $type = localStorage.getItem("toastr_type");
  localStorage.removeItem("toastr_type");

  const $message = localStorage.getItem("toastr_message");
  localStorage.removeItem("toastr_message");

  if ($type && $type.length && $message && $message.length) {
    return toastr[$type]($message);
  }
};

const onPaymentPage = function() {
  let needle;
  registerOrderListEvents();
  calculateOrderAmountsSum();
  updateShoppingCartCount();

  const $related_height = $("div#related_payments").height();
  const $fieldset_height = $("fieldset.payment").height();
  if ($related_height > ($fieldset_height - 60)) {
    $("fieldset.payment").height($("div#related_payments").height() + 60);
    $("#payment_update_button").css("margin-top", ($("fieldset.payment").height() - $fieldset_height) + 10);
  }

  let $orders = [];
  try { $orders = JSON.parse(Cookies.get("orders")); }
  catch (e) {}

  if ((needle = parseInt(Cookies.get("cart")), Array.from($orders).includes(needle))) {
    showCart();
  } else {
    hideCart();
  }

  return runToastr();
};


$(document).ready(onPaymentPage);
$(document).on("turbolink:load", onPaymentPage);

// Code from new_payment.coffee
const onPaymentCompletion = function(response) {
  // if response["closeIFrame"] == true
  //   return

  const $note = $("#payment_note").val();
  // alert(JSON.stringify(response))
  const $log_note = "order_amounts: " + buildOrderAmountsParam() + "\n" +
    "\n orginal note: '" + $note + "'\n" +
    "\n response: " + JSON.stringify(response);
  $("#payment_note").val($log_note);

  $.ajax("/payments/manual_card_entry", {
    type: "POST",
    dataType: "json",
    data: {
      "order_amounts": buildOrderAmountsParam(),
      "note": $note,
      "response": JSON.stringify(response),
      "payment_note": $log_note
      },
    error(jqXHR, textStatus, errorThrown) {
      return alert(textStatus + " - " + errorThrown);
    },
    success() {
      updateShoppingCartCount();
      return location.href = location.href.split("?")[0];
    }
  });
      // alert('success!')

};

const positionOn = function(original, to_move, padding) {
    if (padding == null) { padding = 0; }
    const $position = original.position();

    const $x      = $position.left;
    const $y      = $position.top;

    return to_move.css({
      position: "absolute",
      zIndex:   5000,
      top:      $y + padding,
      left:     $x + padding
    });
  };

const copyOrderAmounts = function() {
  const $orders = $(".order_amounts input");
  const $visible_orders = $(".visible_order_amounts input");

  $orders.each(function(index, element) {
    const $visible_element = $visible_orders[index];

    if ((index % 2) === 0) {
      // Order ID
      if ($(element).val() !== $($visible_element).val()) {
        return console.log($(element).val() + " not equal to " + $($visible_element).val());
      }
    } else {
      // Order Amount
      return $(element).val($($visible_element).val());
    }
  });

  return $("#hidden_payment_total").val($("#visible_payment_total").val());
};


const enableField = function(field, msg = null) {
  $(field).removeAttr("disabled");
  if (msg === null) {
    return $(field).removeAttr("title");
  } else {
    return $(field).attr('title', msg);
  }
};

const disableField = function(field, msg) {
  $(field).attr('disabled','disabled');
  return $(field).attr('title', msg);
};

const showHideFields = function() {
  let needle;
  if ($("#tender_select").val() === "check") {
    enableField("#check_number", "Numbers and dashes only");
    enableField("#visible_payment_total");
    return disableField("#card_number", "Select tender 'Debit Card' or 'Credit Card' to enable.");
  } else if ($("#tender_select").val() === "cash") {
    enableField("#visible_payment_total");
    disableField("#card_number", "Select tender 'Debit Card' or 'Credit Card' to enable.");
    return disableField("#check_number", "Select tender 'Check' to enable.");
  } else if ((needle = $("#tender_select").val(), ["credit_card", "debit_card"].includes(needle))) {
    enableField("#card_number");
    disableField("#check_number", "Select tender 'Check' to enable.");
    return disableField("#visible_payment_total", "Automatically calculated from 'Amount To Pay' fields.");
  } else {
    disableField("#card_number", "Select tender 'Debit Card' or 'Credit Card' to enable.");
    disableField("#check_number", "Select tender 'Check' to enable.");
    return disableField("#visible_payment_total", "Automatically calculated from 'Amount To Pay' fields.");
  }
};

var buildOrderAmountsParam = function() {
  let $order_amounts = "[";
  const $orders = $(".order_amounts input");
  $orders.each(function(index, element) {
    if ((index % 2) === 0) {
      return $order_amounts += "{\"order_id\":" + $(element).val() + ",";
    } else {
      $order_amounts += "\"amount\":" + $(element).val() + "}";
      if (index !== ($orders.length - 1)) {
        return $order_amounts += ",";
      }
    }
  });

  return $order_amounts += "]";
};

// Receipt Dialog
const dialogInitialize = function() {
  let auto_open = false;
  if (Cookies.get("sale_complete")) {
    auto_open = true;
    Cookies.remove("sale_complete");
  }

  return $("#receipt_dialog").dialog({
    autoOpen: auto_open,
    height: 250,
    width: 450,
    modal: true,
    buttons: {
      "Return to Transaction"() {
        return location.href = location.href.split("?")[0];
      },
      "No Receipt/Close"() {
        return $(this).dialog( "close" );
      }
    },
    close() {
      return transactionComplete();
    }
  });
};

var transactionComplete = () => $.ajax({
  url: "/close_sales_transaction",
  type: "GET",
  success(data) {
    Cookies.remove("orders");
    Cookies.remove("transaction_orders");
    return location.href = location.href.split("?")[0];
  },
  error(data) {}
});


const registerNewPaymentEvents = function() {
  // Manual Payment Button
  $("#velocity_button_placeholder").click(function(e) {
    e.preventDefault();

    let $orders = [];
    try { $orders = JSON.parse(Cookies.get("orders")); }
    catch (error) { e = error; }
    if ($orders.length <= 0) {
      toastr["error"](
        "Please add an order to the cart before taking a payment!"
      );
      return false;
    }

    const $amount = calculateTotal();
    if ($amount <= 0.0001) {
      toastr["error"](
        `Please set at least one 'Amount To Pay' \
greater than zero before taking a payment.`
      );
      return false;
    }

    copyOrderAmounts();

    return false;
  });


  // Manual Complete Payment
  $("#manual_complete_transaction > a").click(function(e) {
    e.preventDefault();
    if (transactionOrdersList().length === 0) {
      return toastr["error"]("No transaction has been started.");
    } else {
      return $("#receipt_dialog").dialog("open");
    }
  });

  $("#tender_select").change(() => showHideFields());

  // Email Receipt
  $("input[name='email_receipt']").click(function(e) {
    if (Cookies.get("keyed_card_payment")) {
      if (confirm("Hosted Checkout sends a receipt to the previously entered email. Do you want to email another receipt?")) {
        Cookies.remove("keyed_card_payment");
        $(this).click();
        return;
      }

      e.preventDefault();
      return false;
    }
  });

  // Print Receipt
  $("[name='print_receipt']").click(function(e) {
    e.preventDefault();
    $("#sales_transaction_email").removeAttr("required");
    $("#sales_transaction_email").removeAttr("type");
    $(this).unbind();
    $(this).click();
    const callback = () => location.reload();
    return setTimeout(callback, 2000);
  });

  // Submit New Payment Form
  return $("#new_payment_form_2").submit(function(e) {
    e.preventDefault();
    copyOrderAmounts();
    return e.currentTarget.submit();
  });
};


const onPaymentNewPaymentsPage = function() {
  registerNewPaymentEvents();
  showHideFields();
  dialogInitialize();
  $("#add_item_input").focus();

  // The manual card payment uses this as a callback. Webpack doesn't
  // allow @ to expose functions globally so we must attach the function
  // to the window.
  return global.onPaymentCompletion = onPaymentCompletion;
};


$(document).ready(onPaymentNewPaymentsPage);
$(document).on("turbolink:load", onPaymentNewPaymentsPage);
