/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// @checkUnitPriceNotZero = (input) ->
//   if (parseFloat(input.value) == 0.0)
//     input.setCustomValidity("The unit price must not be zero.")
//   else
//     # input is fine -- reset the error message
//     input.setCustomValidity("")

const checkValidNumber = function(input) {
  if ((parseFloat(input.value) === NaN) || (parseFloat(input.value) === Infinity)) {
    return input.setCustomValidity("Please enter a valid number.");
  } else {
    // input is fine -- reset the error message
    return input.setCustomValidity("");
  }
};

const checkValidMarkupRate = function(markup_rate) {
  if (markup_rate < 0) {
    $('#part_markup_rate')[0].setCustomValidity("Markup rate cannot be negative.");
    return console.log("1");
  } else {
    return $('#part_markup_rate')[0].setCustomValidity("");
  }
};

// Automatically update various parts field when other
// input is edited.
Number.prototype.toFixedDown = function(digits) {
  const re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)');
  const m = this.toString().match(re);
  if (m) { return parseFloat(m[1]); } else { return this.valueOf(); }
};

const partsEvents = () => $('#part_markup_rate').on('input', () => checkValidNumber($("#part_markup_rate")[0]));

const partsCalculator = function() {
  $("#part_total_including_markup").bind("input focusout", function() {
    const total_including_markup = parseFloat($(this).val());
    const unit_price = parseFloat($("#part_unit_price").val());
    const quantity = parseInt($("#part_quantity").val()) || 1;
    const total = unit_price * quantity;
    let markup_rate = ((total_including_markup - total) / total) * 100.0;

    discountCalculation(total_including_markup);

    // Database limits precision to 7.
    markup_rate = parseFloat(markup_rate.toFixed(7));

    let precision = 7;
    while ((total * (1 + (markup_rate/100.0))) !== total_including_markup) {
      precision--;
      markup_rate = parseFloat(markup_rate.toFixed(precision));

      if ((total * (1 + (markup_rate/100.0))) !== total_including_markup) {
        const markup_rate_ceiling = parseFloat(
          (markup_rate + (5 / Math.pow(10, precision))).toFixed(precision)
        );
        if ((total * (1 + (markup_rate_ceiling/100.0))) !== total_including_markup) {
          $('#part_markup_rate').val(markup_rate_ceiling.toFixed(precision));
          return;
        }
      } else {
        $('#part_markup_rate').val(markup_rate.toFixed(precision));
        return;
      }

      if (precision === 0) {
        $('#part_markup_rate').val("Error - Could not calculate a valid Markup Rate. Please contact the developer with values used.");
        return;
      }
    }

    $('#part_markup_rate').val(markup_rate);

    return checkValidMarkupRate(markup_rate);
  });


  $("#part_markup_rate").bind("input focusout", function() {
    const markup_rate = parseFloat($(this).val());
    checkValidNumber(this);
    const unit_price = parseFloat($("#part_unit_price").val());
    const quantity = parseInt($("#part_quantity").val()) || 1;
    const total = unit_price * quantity;
    const total_including_markup = total + ((markup_rate * total) / 100.0);
    $("#part_total_including_markup").val((total_including_markup.toFixedDown(2)).toFixed(2));

    discountCalculation(total_including_markup);

    return checkValidMarkupRate(markup_rate);
  });

  $("#part_quantity").bind("input focusout", function() {
    const unit_price = parseFloat($("#part_unit_price").val());
    const quantity = parseInt($(this).val()) || 1;
    const total = unit_price * quantity;
    const markup_rate = parseFloat($("#part_markup_rate").val());
    checkValidNumber($("#part_markup_rate")[0]);
    const total_including_markup = total + ((markup_rate * total) / 100.0);
    $("#part_total_including_markup").val((total_including_markup.toFixedDown(2)).toFixed(2));

    discountCalculation(total_including_markup);

    return checkValidMarkupRate(markup_rate);
  });

  $("#part_unit_price").bind("input focusout", function() {
    const unit_price = parseFloat($(this).val());
    const quantity = parseInt($("#part_quantity").val()) || 1;
    const total = unit_price * quantity;
    const markup_rate = parseFloat($("#part_markup_rate").val());
    checkValidNumber($("#part_markup_rate")[0]);
    const total_including_markup = total + ((markup_rate * total) / 100.0);
    $("#part_total_including_markup").val((total_including_markup.toFixedDown(2)).toFixed(2));

    discountCalculation(total_including_markup);

    return checkValidMarkupRate(markup_rate);
  });

  $("#part_discount").bind("input focusout", function() {
    // console.log("-----part_discount event----")
    const unit_price = parseFloat($("#part_unit_price").val());
    const quantity = parseInt($("#part_quantity").val()) || 1;
    const total = unit_price * quantity;
    const markup_rate = parseFloat($("#part_markup_rate").val());
    checkValidNumber($("#part_markup_rate")[0]);
    const total_including_markup = total + ((markup_rate * total) / 100.0);
    $("#part_total_including_markup").val((total_including_markup.toFixedDown(2)).toFixed(2));

    discountCalculation(total_including_markup);

    return checkValidMarkupRate(markup_rate);
  });

  return $("#part_discount_amount").bind("input focusout", function() {
    // console.log("-----part_discount_amount event----")
    const unit_price = parseFloat($("#part_unit_price").val());
    const quantity = parseInt($("#part_quantity").val()) || 1;
    const total = unit_price * quantity;
    const markup_rate = parseFloat($("#part_markup_rate").val());
    checkValidNumber($("#part_markup_rate")[0]);
    const total_including_markup = parseFloat(total + ((markup_rate * total) / 100.0));
    $("#part_total_including_markup").val((total_including_markup.toFixedDown(2)).toFixed(2));

    const discount_amount = parseFloat($(this).val());
    let discount = parseFloat((discount_amount / total_including_markup) * 100.0);
    $("#part_discount_amount").val(discount_amount.toFixed(2));
    const total_price = total_including_markup - discount_amount;
    $("#part_total_price").val((total_including_markup - discount_amount).toFixed(2));

    // Database limits precision to 7.
    discount = parseFloat(discount.toFixed(7));

    let precision = 7;
    // console.log("discount", discount)
    // console.log("total_including_markup", total_including_markup)
    // console.log("as float", total_including_markup * (1 + discount / 100.0))
    // console.log("total_price", total_price)
    // discount = parseFloat($("#part_discount").val()) || 0.0
    while ((total_including_markup * (1 + (discount / 100.0))) !== total_price) {
      precision--;
      discount = parseFloat(discount.toFixed(precision));

      if ((total_including_markup * (1 + (discount/100.0))) !== total_price) {
        const discount_ceiling = parseFloat(
          (discount + (5 / Math.pow(10, precision))).toFixed(precision)
        );
        if ((total_including_markup * (1 + (discount_ceiling/100.0))) !== total_price) {
          $('#part_discount').val(discount_ceiling.toFixed(precision));
          // console.log("-----------------------------------")
          return;
        }
      } else {
        $('#part_discount').val(discount.toFixed(precision));
      }

      if (precision === 0) {
        $('#part_discount').val("Error - Could not calculate a valid discount percentage. Please contact the developer with values used.");
        // console.log("------------------------------------")
        return;
      }
    }

    $('#part_discount').val(discount.toFixed(precision));

    return checkValidMarkupRate(markup_rate);
  });
};

var discountCalculation = function(total_including_markup) {
  // console.log("-----discountCalculation function----")
  // console.log("total_including_markup", total_including_markup)
  const discount = parseFloat($("#part_discount").val()) || 0.0;
  // console.log("discount", discount)
  const discount_amount = (parseFloat(total_including_markup) * (discount / 100.0)).toFixedDown(2);
  // console.log("discount_amount", discount_amount)
  $("#part_discount_amount").val(discount_amount.toFixed(2));
  // console.log("total_including_markup - discount_amount", (total_including_markup - discount_amount).toFixed 2)
  return $("#part_total_price").val((total_including_markup - discount_amount).toFixed(2));
};
  // console.log("------------------------------------")

$(document).ready(partsCalculator);
$(document).on('turbolink:load', partsCalculator);
