/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const printRefresh = () => $("#print_submit").click(event => setTimeout("location.reload();", 1000));

const costEstimateCalculation = () => $("#service_request_services").change(function(event) {
  let $cost_estimate = 0.0;
  const selected = event.target.selectedOptions;
  for (let item of Array.from(selected)) {
    $cost_estimate += parseFloat(item.getAttribute("data-cost"));
  }

  return $("#service_request_cost_estimate").val($cost_estimate);
});

this.registerServiceRequestEvents = function() {
  printRefresh();
  costEstimateCalculation();

  return $("#new_recurring_invoice").click(function(e) {
    // $("#recurring_invoice_dialog").dialog()
    $("#recurring_invoice_dialog").dialog({
      autoOpen: true, // Open dialog on click
      height: 400,
      width: 500,
      modal: true,
      buttons: {
      },
      close() {}
    });

    return e.preventDefault();
  });
};

$(document).ready(this.registerServiceRequestEvents);
$(document).on("turbolink:load", this.registerServiceRequestEvents);
