import { Controller } from '@hotwired/stimulus'
import axios from 'axios'
import setupCSRFToken from '@/helpers/axios-csrf-token'

export default class extends Controller {
  static targets = ['event']
  static values = { day: String, hour: String }

  connect() {
    if (!this.constructor.menu) {
      this.constructor.menu = document.createElement('div')
      this.constructor.menu.classList.add(
        'hidden', 'absolute', 'bg-white', 'border', 'border-gray-300',
        'rounded-lg', 'shadow-lg', 'z-50', 'divide-y', 'divide-gray-200'
      )
      document.body.appendChild(this.constructor.menu)
      setupCSRFToken()
    }

    this.element.addEventListener('contextmenu', this.showMenu.bind(this))
  }

  showMenu(event) {
    event.preventDefault()

    this.hideMenu()
    this.constructor.menu.innerHTML = ''

    // Look for an element with data attributes 'data-event-id' and 'data-event-url'
    const eventElement = event.target.closest('[data-event-id][data-event-url]')
    if (eventElement) {
      // Directly pass eventElement to handleEdit and handleDelete
      this.addMenuItem('Edit Event', () => this.handleEdit(eventElement))
      this.addMenuItem('Delete Event', () => this.handleDelete(eventElement))
    } else {
      this.addMenuItem('Create New Event', this.createEvent.bind(this))
    }

    this.constructor.menu.style.top = `${event.pageY}px`
    this.constructor.menu.style.left = `${event.pageX}px`
    this.constructor.menu.classList.remove('hidden')
    document.addEventListener('click', this.hideMenu.bind(this), { once: true })
  }

  hideMenu() {
    this.constructor.menu.classList.add('hidden')
  }

  addMenuItem(text, action) {
    const item = document.createElement('div')
    item.textContent = text
    item.classList.add('px-4', 'py-2', 'hover:bg-gray-100', 'cursor-pointer', 'text-sm', 'text-gray-700')
    item.addEventListener('click', action)
    this.constructor.menu.appendChild(item)
  }

  createEvent() {
    const createUrl = `/application_events/new?day=${this.dayValue}&hour=${this.hourValue}`
    window.location.href = createUrl
  }

  handleEdit(eventElement) {
    const eventUrl = eventElement.dataset.eventUrl
    window.location.href = eventUrl
  }

  handleDelete(eventElement) {
    const eventId = eventElement.dataset.eventId

    axios.delete(`/application_events/${eventId}`, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => {
      if (response.status === 200) {
        eventElement.remove()
      } else {
        console.error('Failed to delete event')
      }
    })
    .catch(error => {
      console.error('Error:', error)
    })
  }
}
