import consumer from "./consumer"

document.addEventListener("DOMContentLoaded", () => {
  const form = document.getElementById("message-form")
  const messageList = document.getElementById("message-list")
  const messageInput = document.getElementById("message-input")
  const usernameInput = document.getElementById("username")

  if (form && messageList && messageInput && usernameInput) {
    const chatroomSubscription = consumer.subscriptions.create("ChatroomChannel", {
      connected() {
        console.log("Connected to the chatroom!")
      },

      disconnected() {
        console.log("Disconnected from the chatroom.")
      },

      received(data) {
        messageList.insertAdjacentHTML("beforeend", `<li><strong>${data.username}:</strong> ${data.message}</li>`)
      },

      speak(message, username) {
        this.perform("speak", { message: message, username: username })
      }
    })

    form.addEventListener("submit", (event) => {
      event.preventDefault()

      const message = messageInput.value
      const username = usernameInput.value
      chatroomSubscription.speak(message, username)

      messageInput.value = ""
    })
  }
})
